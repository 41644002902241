











import { Component, Mixins, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import MyAddressList from "@/views/Mine/MoreServices/Address/MyAddressList.vue";

@Component({
  components: {
    MyAddressList,
  },
})
export default class ExternalAddressPage extends Mixins(Mixin) {
  public uuid = "";
  public showId = "";
  mounted(): void {
    // 非首次进入页面时需要在 mounted 中刷新页面
    if (this.ExternalAccessModule.isExternalAccessReady) {
      this.init();
    }
  }

  /**
   * 外部跳转 H5 异步获取 token、channel 的异步请求完成后执行
   * 一定在 mounted 之后
   */
  @Watch("ExternalAccessModule.isExternalAccessReady")
  readyStateChange(isExternalAccessReady: boolean): void {
    if (isExternalAccessReady) {
      this.init();
    }
  }

  init(): void {
    let query = this.$route.query as any;
    this.uuid = query.uuid;
    this.showId = query.showId;
    this.$nextTick(() => {
      (this.$refs.address as MyAddressList).refreshData();
    });
  }

  setAddress(data: member.NewAddrRes): void {
    this.jsBridge("onSelectAddress", data);
  }
  close(): void {
    this.jsBridge("back", "", () => {
      this.$router.go(-1);
    });
  }
}
